<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  <!-- @if(showToggle) {
  <button mat-icon-button (click)="toggleCollapsed.emit()" class="d-flex justify-content-center">
    <i-tabler name="align-left" class="icon-20 d-flex"></i-tabler>
  </button>
  } -->

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button mat-icon-button (click)="toggleMobileNav.emit()" class="d-flex justify-content-center">
    <i-tabler name="align-left" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- --------------------------------------------------------------- -->

  <div class="d-none d-lg-flex">
    <!-- --------------------------------------------------------------- -->
    <!--  Links -->
    <!-- --------------------------------------------------------------- -->
    <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
      <div class="row">
        <div class="col-sm-8 b-r-1 p-r-0">
          <div class="p-32 p-b-0">
            <div class="row">
              @for(appdd of apps; track appdd.title) {
              <div class="col-sm-6 text-hover-primary">
                <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                  <span
                    class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center">
                    <img [src]="appdd.img" width="20" />
                  </span>

                  <div class="m-l-16">
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
          <div class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between">
            <span class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500">
              <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
              Asked Questions
            </span>
            <a [routerLink]="['/theme-pages/faq']" mat-flat-button color="primary">Check</a>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="p-x-16 p-y-32">
            <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
            @for(quicklink of quicklinks; track quicklink.title) {
            <div class="text-hover-primary">
              <a [routerLink]="[quicklink.link]"
                class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8">{{ quicklink.title }}</a>
            </div>
            }
          </div>
        </div>
      </div>
    </mat-menu>
    <a mat-button [routerLink]="['/apps/oficio']">Cadastro Oficio</a>
    <a mat-button [routerLink]="['/apps/oficioList']">Lista Oficios</a>
    <a mat-button [routerLink]="['/apps/investigadosList']">Lista Investigado</a>
    <a mat-button [routerLink]="['/apps/oficioStatus']">Fluxo Ofício</a>
    <a mat-button [routerLink]="['/apps/cartaResposta/:id']">Carta Resposta</a>
  </div>

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button mat-icon-button (click)="toggleMobileFilterNav.emit()" class="d-flex d-lg-none justify-content-center">
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <button mat-stroked-button class="d-none d-lg-flex" (click)="openDialog()">
    <div class="d-flex align-items-center">
      <i-tabler name="search" class="icon-20 d-flex m-r-10"></i-tabler> Instituição
    </div>
  </button>

 

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-fab extended color="inherit" [matMenuTriggerFor]="profileMenu" aria-label="Notifications">
    <div class="d-flex align-items-center">
      <img src="/assets/images/profile/user5.jpg" class="rounded-circle object-cover" width="40" />

      <div class="m-l-16 text-left d-none d-lg-flex">
        <div>
          <h5 class="f-s-16">Andre Dantas</h5>
          <span class="f-s-14 mat-body-1">Admin</span>
        </div>
      </div>
    </div>
  </button>
  <mat-menu #profileMenu="matMenu" xPosition="before" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 495px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img src="/assets/images/profile/user5.jpg" class="rounded-circle" width="95" />
          <div class="m-l-16">
            <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Andre Dantas</h6>
            <span class="f-s-14 mat-body-1 d-block m-b-4">Admin</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              info&#64;spike.com
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        @for(profile of profiledd; track profile.title) {
        <a class="p-y-16 text-decoration-none d-block text-hover-primary" [routerLink]="[profile.link]">
          <div class="d-flex align-items-center">
            <span class="text-{{ profile.color }} bg-light-{{
                profile.color
              }} icon-50 d-flex align-items-center justify-content-center shadow-none rounded">
              <i-tabler [name]="profile.img" class="icon-20" />
            </span>

            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
        }
      </div>

      <div class="p-y-12 p-x-32">
        <button [routerLink]="['/authentication/login']" mat-flat-button color="primary" class="w-100">
          Logout
        </button>
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>