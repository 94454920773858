<mat-toolbar class="topbar horizontal-topbar">
  <div class="container">
    <div class="d-none d-sm-flex">
      <app-branding></app-branding>
    </div>

    <!-- Mobile Menu -->
    <button
      mat-icon-button
      (click)="toggleMobileNav.emit()"
      class="d-block d-lg-none"
    >
      <i-tabler name="align-left" class="icon-20 d-flex"></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <div class="d-none d-lg-flex align-items-centerlink-container">
      <!-- --------------------------------------------------------------- -->
      <!--  Links -->
      <!-- --------------------------------------------------------------- -->


      <a mat-button [routerLink]="['/apps/oficioStatus']"><strong>Fluxo Ofício</strong></a>
      <a mat-button [routerLink]="['/apps/oficioList']"><strong>Lista Oficios</strong></a>
      <a mat-button [routerLink]="['/apps/investigadosList']"><strong>Lista Investigado</strong></a>


    </div>

    <span class="flex-1-auto"></span>

    <!-- Mobile Menu -->
    <button
      mat-icon-button
      (click)="toggleMobileFilterNav.emit()"
      class="d-flex d-lg-none justify-content-center"
    >
      <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
    </button>

    <!-- --------------------------------------------------------------- -->
    <!--  Search -->
    <!-- --------------------------------------------------------------- -->
    <button mat-stroked-button class="d-none d-lg-flex" (click)="openDialog()">
      <div class="d-flex align-items-center">
        <i-tabler name="search" class="icon-20 d-flex m-r-10"></i-tabler>
        Instituição
      </div>
    </button>

    <!-- --------------------------------------------------------------- -->
    <!-- profile Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <button
      mat-fab
      extended
      color="inherit"
      [matMenuTriggerFor]="profileMenu"
      aria-label="Notifications"
    >
      <div class="d-flex align-items-center">
        <img
          src="/assets/images/profile/user5.jpg"
          class="rounded-circle object-cover"
          width="40"
        />

        <div class="m-l-16 text-left d-none d-lg-flex">
          <div>
            <h5 class="f-s-16">Andre Dantas</h5>
            <span class="f-s-14 mat-body-1">Admin</span>
          </div>
        </div>
      </div>
    </button>
    <mat-menu
      #profileMenu="matMenu"
      xPosition="before"
      class="topbar-dd cardWithShadow"
    >
      <ng-scrollbar class="position-relative" style="height: 490px">
        <div class="p-x-32 p-y-16">
          <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

          <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
            <img
              src="/assets/images/profile/user5.jpg"
              class="rounded-circle"
              width="95"
            />
            <div class="m-l-16">
              <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Andre Dantas</h6>
              <span class="f-s-14 mat-body-1 d-block m-b-4">Admin</span>
              <span class="d-flex align-items-center">
                <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
                info&#64;spike.com
              </span>
            </div>
          </div>
        </div>
        <div class="p-x-32">
          @for(profile of profiledd; track profile) {
          <a
            class="p-y-16 text-decoration-none d-block text-hover-primary"
            [routerLink]="[profile.link]"
          >
            <div class="d-flex align-items-center">
              <span
                class="text-{{ profile.color }} bg-light-{{
                  profile.color
                }} icon-50 d-flex align-items-center justify-content-center shadow-none rounded"
              >
                <i-tabler [name]="profile.img" class="icon-20" />
              </span>

              <div class="m-l-16">
                <h5
                  class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
                >
                  {{ profile.title }}
                </h5>
                <span class="mat-body-1">{{ profile.subtitle }}</span>
              </div>
            </div>
          </a>
          }
        </div>

        <div class="p-y-12 p-x-32">
          <button
            [routerLink]="['/authentication/login']"
            mat-flat-button
            color="primary"
            class="w-100"
          >
            Logout
          </button>
        </div>
      </ng-scrollbar>
    </mat-menu>
  </div>
</mat-toolbar>
